export const questions = [
    {
        question: "Dans quel type d'environnement préfères-tu travailler ? 🏡",
        options: ["Bureau", "Extérieur", "Lieux variés", "Domicile"]
    },
    {
        question: "Comment préfères-tu travailler la plupart du temps ? 💼",
        options: ["Seul(e)", "Petite équipe", "Grande équipe", "Alternant travail individuel et collectif"]
    },
    {
        question: "Quel type de tâches te motive le plus ? 💪",
        options: ["Résoudre des problèmes complexes", "Créer ou concevoir de nouvelles choses", "Aider ou enseigner aux autres", "Organiser et planifier"]
    },
    {
        question: "Quelle est ton attitude face aux nouvelles technologies ? 📱",
        options: ["Je les adopte avec enthousiasme", "Je les utilise si nécessaire", "Je préfère les méthodes traditionnelles", "Ça dépend de la technologie"]
    },
    {
        question: "Comment gères-tu le stress au travail ? 🌋",
        options: ["Je reste calme et organisé(e)", "Je cherche le soutien de mes collègues", "Je fais des pauses régulières", "J'ai du mal à gérer le stress"]
    },
    {
        question: "Quelle importance accordes-tu à l'équilibre travail-vie personnelle ? 🍃",
        options: ["C'est ma priorité absolue", "C'est important, mais je peux faire des compromis", "Je privilégie ma carrière actuellement", "Je n'y pense pas vraiment"]
    },
    {
        question: "Quel type de reconnaissance te motive le plus ? 🚀",
        options: ["Les augmentations de salaire", "Les promotions et l'évolution de carrière", "La reconnaissance verbale et les compliments", "La satisfaction personnelle du travail bien fait"]
    },
    {
        question: "Comment réagis-tu face à de nouveaux défis ? 🏋️‍♀️",
        options: ["Avec enthousiasme, j'aime apprendre", "Avec prudence, mais je suis prêt(e) à essayer", "Je préfère rester dans ma zone de confort", "Ça dépend du défi"]
    },
    {
        question: "Quel rythme de travail te convient le mieux ? 📝",
        options: ["Régulier et prévisible", "Varié avec des pics d'activité", "Intense et rapide", "Flexible et adaptable"]
    },
    {
        question: "Parmi ces domaines, lequel correspond le plus à tes passions et intérêts ? 🌟",
        options: ["Sciences et technologies", "Arts et créativité", "Relations humaines et social", "Nature et environnement", "Commerce et entrepreneuriat", "Santé et bien-être"]
    },
    {
        question: "Quelle est ton approche de la prise de décision ? 🧩",
        options: ["J'analyse tous les aspects avant de décider", "Je fais confiance à mon intuition", "Je consulte les autres avant de décider", "Je préfère que quelqu'un d'autre décide"]
    },
    {
        question: "Comment préfères-tu communiquer au travail ? 🗣️",
        options: ["Par écrit (e-mails, messages)", "En personne, face à face", "Par téléphone ou visioconférence", "Un mélange de toutes ces méthodes"]
    },
    {
        question: "Quelle est ton attitude face au changement dans le milieu professionnel ? 🖥️",
        options: ["J'accueille le changement avec enthousiasme", "Je m'adapte, mais je préfère la stabilité", "J'ai besoin de temps pour m'adapter", "Je résiste au changement"]
    },
    {
        question: "Quel type de responsabilités préfères-tu ? 👔",
        options: ["Diriger une équipe", "Être expert(e) dans mon domaine", "Travailler en autonomie sur mes projets", "Collaborer au sein d'une équipe sans responsabilité hiérarchique"]
    },
    {
        question: "Quelle importance accordes-tu à la créativité dans ton travail ? 🎨",
        options: ["C'est essentiel, je veux créer tous les jours", "J'aime avoir des opportunités créatives occasionnelles", "Je préfère suivre des procédures établies", "La créativité n'est pas importante pour moi au travail"]
    },
    {
        question: "Quel type d'impact souhaites-tu avoir grâce à ton travail ? 💫",
        options: ["Contribuer à la société ou à une cause importante", "Innover et faire avancer mon domaine", "Assurer ma stabilité financière et celle de ma famille", "Me développer personnellement et professionnellement"]
    }
];