import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function ComingsoonPage() {
    useEffect(() => {
      
        document.body.style.overflow = 'hidden';
        
    
        const footer = document.querySelector('footer');
        if (footer) {
            footer.style.display = 'none';
        }

       
        document.addEventListener('contextmenu', e => e.preventDefault());
        document.addEventListener('keydown', e => {
            if (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i')) {
                e.preventDefault();
            }
        });

        
        return () => {
            document.body.style.overflow = 'auto';
            if (footer) {
                footer.style.display = 'block';
            }
            document.removeEventListener('contextmenu', e => e.preventDefault());
            document.removeEventListener('keydown', e => e.preventDefault());
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Coming Soon - Trouve Ton Métier</title>
                <meta
                    name="description"
                    content="Notre quiz d'orientation professionnelle sera bientôt disponible."
                />
                <link
                    rel="canonical"
                    href="https://trouvetonmetier.fr"
                />
                <style>
                    {`
                    * {
                        user-select: none;
                        -webkit-user-select: none;
                    }
                    `}
                </style>
            </Helmet>

            <div className="page active homepage" style={{height: '100vh', overflow: 'hidden'}}>
                <h1>Coming Soon</h1>
                
                <div className="home-content">
                    <p>
                        Notre quiz d'orientation professionnelle sera bientôt disponible.
                        <br />
                        Revenez nous voir prochainement !
                    </p>
                </div>
            </div>
        </>
    );
}

export default ComingsoonPage;
