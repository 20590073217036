import React from 'react';

function PrivacyPage({ onBack }) {
  return (
    <div className="page active">
      <h2 className="legalt">Politique de confidentialité</h2>
      <p className="legalp">
        Dernière mise à jour : 19/09/2024

        <h5>Introduction</h5>
        La protection de vos données personnelles est une priorité pour Trouve ton métier.
        <br />
        Cette politique de confidentialité décrit la manière dont nous collectons, utilisons et protégeons vos informations.

        <h5>1. Identité des responsables de traitement</h5>

        <h5>Responsable principal</h5>
        - Baret Benjamin
        <br />
        - 122 rue Amelot 75011 PARIS
        <br />
        - contact.ttmweb@gmail.com
        <br />
        - RCS : 979 942 471 R.C.S. Paris

        <h5>Sous-traitant technique</h5>
        - Vercel Inc. (hébergeur)
        <br />
        - 340 S Lemon Ave #4133 Walnut, CA 91789
        <br />
        - Rôle : hébergement et analyse technique du site

        <h5>2. Données collectées</h5>

        <h5>2.1 Données collectées automatiquement</h5>
        Via notre hébergeur Vercel Inc. :
        <br />
        - Adresse IP (anonymisée)
        <br />
        - Date et heure de connexion
        <br />
        - Pages visitées
        <br />
        - Type de navigateur
        <br />
        - Système d'exploitation

        <h5>2.2 Données de navigation</h5>
        - Données techniques nécessaires à la navigation
        <br />
        - Cookies essentiels au fonctionnement
        <br />
        - Statistiques d'utilisation anonymes

        <h5>2.3 Données du quiz</h5>
        - Réponses au questionnaire (anonymes)
        <br />
        - Résultats générés

        <h5>3. Finalités et base légale</h5>

        <h5>3.1 Fonctionnement du service</h5>
        - Finalité : permettre l'accès au quiz et générer les résultats
        <br />
        - Base légale : intérêt légitime

        <h5>3.2 Amélioration du service</h5>
        - Finalité : analyse statistique et optimisation
        <br />
        - Base légale : intérêt légitime

        <h5>3.3 Mesure d'audience</h5>
        - Finalité : statistiques de fréquentation
        <br />
        - Base légale : consentement (via cookies)

        <h5>4. Destinataires des données</h5>

        <h5>4.1 Accès interne</h5>
        - Équipe de Trouve ton métier : accès limité aux données anonymisées

        <h5>4.2 Sous-traitants</h5>
        - Vercel Inc. : hébergement et données techniques
        <br />
        - Aucun autre sous-traitant n'a accès à vos données

        <h5>4.3 Transfert hors UE</h5>
        - Certaines données peuvent être transférées aux États-Unis via Vercel Inc.
        <br />
        - Transferts encadrés par les Clauses Contractuelles Types de l'UE
        <br />
        - Garanties appropriées mises en place

        <h5>5. Durée de conservation</h5>
        - Données de navigation : 13 mois maximum
        <br />
        - Cookies : 13 mois maximum
        <br />
        - Données du quiz : non conservées après la session
        <br />
        - Statistiques : conservées sous forme anonyme

        <h5>6. Sécurité des données</h5>
        Nous mettons en œuvre les mesures techniques et organisationnelles appropriées pour assurer la sécurité de vos données :
        <br />
        - Chiffrement des données
        <br />
        - Accès limité aux données
        <br />
        - Protocole HTTPS
        <br />
        - Anonymisation quand possible

        <h5>7. Vos droits</h5>
        Conformément au RGPD, vous disposez des droits suivants :
        <br />
        - Droit d'accès
        <br />
        - Droit de rectification
        <br />
        - Droit à l'effacement
        <br />
        - Droit à la limitation du traitement
        <br />
        - Droit à la portabilité
        <br />
        - Droit d'opposition
        <br />
        - Droit de retirer votre consentement
        <br />
        - Droit d'introduire une réclamation auprès de la CNIL
        <br /><br />

        Pour exercer vos droits :
        <br />
        - Concernant les données que nous traitons : contact.ttmweb@gmail.com
        <br />
        - Concernant les données traitées par Vercel : contacter directement Vercel Inc.
        <br /><br />

        Nous nous engageons à répondre à toute demande dans un délai d'un mois.

        <h5>8. Cookies</h5>

        <h5>8.1 Types de cookies utilisés</h5>
        - Cookies techniques (nécessaires au fonctionnement)
        <br />
        - Cookies d'analyse (Vercel Inc.)

        <h5>8.2 Gestion des cookies</h5>
        Vous pouvez gérer vos préférences via :
        <br />
        - Le bandeau de cookies
        <br />
        - Les paramètres de votre navigateur
        <br />
        - Notre page de gestion des cookies

        <h5>9. Modifications</h5>
        Nous nous réservons le droit de modifier cette politique de confidentialité.
        <br />
        En cas de changement substantiel, nous vous en informerons via notre site.

        <h5>10. Contact</h5>
        Pour toute question concernant cette politique de confidentialité :
        <br />
        - Email : contact.ttmweb@gmail.com
        <br />
        - Adresse : 122 rue Amelot 75011 PARIS
        <br /><br />
        Dernière mise à jour : 19/09/2024
      </p>
      <br />
      <button className="btn" onClick={onBack}>Retour</button>
    </div>
  );
}

export default PrivacyPage;