import React from 'react';

function LegalPage({ onBack }) {
  return (
    <div className="page active">
      <h2 className="legalt">Conditions Générales d'Utilisation</h2>
      <div className="legalp">
        <p>
          En vigueur au 19/09/2024

          <h5>1. Mentions légales</h5>
          L'édition et l'exploitation du site https://trouvetonmetier.fr/ sont assurées par :
          <br />
          - B.B, Entrepreneur individuel
          <br />
          - Siège social : 122 rue Amelot, 75011 PARIS
          <br />
          - Adresse électronique : contact.ttmweb@gmail.com
          <br />
          - N° RCS : 979 942 471 R.C.S. Paris
          <br />
          - TVA non applicable, art. 293B du CGI
          <br /><br />

          Le site est hébergé par : Vercel Inc., 340 S Lemon Ave #4133 Walnut, CA 91789, États-Unis.

          <h5>2. Services proposés</h5>
          Le site propose les services suivants :
          <br />
          - Quiz d'orientation professionnelle gratuit
          <br />
          - Analyse personnalisée des résultats
          <br />
          - Accès libre sans création de compte

          <h5>3. Protection des données personnelles</h5>

          <h5>3.1 Responsables du traitement</h5>
          - Responsable principal : Benjamin BARET
          <br />
          - Sous-traitant technique : Vercel Inc. (hébergement)

          <h5>3.2 Données collectées et finalités</h5>
          - Données de navigation (via l'infrastructure Vercel)
          <br />
          - Données techniques anonymisées
          <br />
          - Finalités : optimisation du fonctionnement, amélioration continue du service, analyses statistiques anonymes

          <h5>3.3 Bases légales du traitement</h5>
          - Intérêt légitime pour les cookies techniques essentiels
          <br />
          - Consentement explicite pour les cookies analytiques

          <h5>3.4 Transfert de données hors Union Européenne</h5>
          Les services d'hébergement étant fournis par Vercel Inc. (États-Unis), certaines données peuvent faire l'objet d'un transfert hors UE, encadré par les garanties appropriées conformément au RGPD.

          <h5>3.5 Droits des utilisateurs</h5>
          Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants :
          <br />
          - Droit d'accès, de rectification et d'effacement
          <br />
          - Droit d'opposition et de limitation du traitement
          <br />
          - Droit à la portabilité des données
          <br />
          - Droit de retrait du consentement
          <br />
          - Droit d'introduire une réclamation auprès de la CNIL
          <br /><br />

          Pour exercer vos droits :
          <br />
          - Concernant les données du site : contact.ttmweb@gmail.com
          <br />
          - Concernant les données Vercel : contacter directement Vercel Inc.

          <h5>4. Gestion des cookies</h5>

          <h5>4.1 Catégories de cookies utilisés</h5>
          - Cookies techniques essentiels : nécessaires au fonctionnement du site
          <br />
          - Cookies analytiques (Vercel Analytics) : mesure d'audience anonymisée

          <h5>4.2 Paramétrage des cookies</h5>
          - Configuration via le bandeau de consentement
          <br />
          - Paramétrage possible dans les préférences du navigateur
          <br />
          - Conservation limitée à 13 mois maximum

          <h5>5. Propriété intellectuelle</h5>
          L'ensemble des éléments du site (textes, images, code source, etc.) est protégé par le droit d'auteur. Toute reproduction, représentation ou diffusion, totale ou partielle, nécessite l'autorisation écrite préalable de Benjamin BARET.

          <h5>6. Droit applicable et juridiction compétente</h5>
          Les présentes CGU sont régies par le droit français. En cas de litige, les tribunaux français seront seuls compétents.

          <h5>7. Contact</h5>
          Pour toute question relative aux présentes CGU : contact.ttmweb@gmail.com
          <br /><br />

          Dernière mise à jour : 19/09/2024
        </p>
        <br />
        <button className="btn" onClick={onBack}>Retour</button>
      </div>
    </div>
  );
}

export default LegalPage;